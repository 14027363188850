@import '../../../../submodules/src/styles/flex-grid';
@import '../../../../submodules/src/styles/input-group';
@import "../_variables";

#footer {
  margin-top: 0;
}

.bg-404 {
  background-image: url("@{cdn}/universal/pattern/yellow-pattern.png@{cdn_after}");
  background-repeat: repeat;

  .content {
    background-color: rgba(255, 255, 255, .5) !important;
  }
}

.search-404 {
  background-color: rgba(255, 255, 255, .6) !important;

  &:focus {
    background-color: @color-white;
  }
}

.card-404 {
  transition: background-color @transition-default;

  &:hover {
    text-decoration: none;
    background-color: @color-primary-1;
  }

  svg {
    fill: @color-primary-5;
  }
}